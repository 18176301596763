export default [
    {
        "country": "US",
        "name": "United States",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "AF",
        "name": "Afghanistan",
        "currency": "AFN",
        "symbol": '؋'
    },
    {
        "country": "AL",
        "name": "Albania",
        "currency": "ALL",
        "symbol": 'L'
    },
    {
        "country": "DZ",
        "name": "Algeria",
        "currency": "DZD",
        "symbol": 'دج'
    },
    {
        "country": "AS",
        "name": "American Samoa",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "AD",
        "name": "Andorra",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "AO",
        "name": "Angola",
        "currency": "",
        "symbol": ''
    },
    {
        "country": "AI",
        "name": "Anguilla",
        "currency": "XCD",
        "symbol": '$'
    },
    {
        "country": "AG",
        "name": "Antigua and Barbuda",
        "currency": "XCD",
        "symbol": '$'
    },
    {
        "country": "AR",
        "name": "Argentina",
        "currency": "ARS",
        "symbol": '$'
    },
    {
        "country": "AM",
        "name": "Armenia",
        "currency": "AMD",
        "symbol": '֏'
    },
    {
        "country": "AW",
        "name": "Aruba",
        "currency": 'AWG',
        "symbol": 'ƒ'
    },
    {
        "country": "AU",
        "name": "Australia",
        "currency": "AUD",
        "symbol": '$'
    },
    {
        "country": "AT",
        "name": "Austria",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "AZ",
        "name": "Azerbaijan",
        "currency": "AZN",
        "symbol": '₼'
    },
    {
        "country": "BS",
        "name": "Bahamas",
        "currency": "BSD",
        "symbol": '$'
    },
    {
        "country": "BH",
        "name": "Bahrain",
        "currency": "BHD",
        "symbol": '.د.ب'
    },
    {
        "country": "BD",
        "name": "Bangladesh",
        "currency": "BDT",
        "symbol": '৳'
    },
    {
        "country": "BB",
        "name": "Barbados",
        "currency": "BBD",
        "symbol": '$'
    },
    {
        "country": "BY",
        "name": "Belarus",
        "currency": 'BYN',
        "symbol": 'Br'
    },
    {
        "country": "BE",
        "name": "Belgium",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "BZ",
        "name": "Belize",
        "currency": 'BZD',
        "symbol": 'BZ$'
    },
    {
        "country": "BJ",
        "name": "Benin",
        "currency": "XOF",
        "symbol": 'CFA'
    },
    {
        "country": "BM",
        "name": "Bermuda",
        "currency": "BMD",
        "symbol": '$'
    },
    {
        "country": "BT",
        "name": "Bhutan",
        "currency": "BTN",
        "symbol": 'Nu.'
    },
    {
        "country": "BO",
        "name": "Bolivia, Plurinational State of",
        "currency": "BOB",
        "symbol": '$b'
    },
    {
        "country": "BA",
        "name": "Bosnia and Herzegovina",
        "currency": "BAM",
        "symbol": 'KM'
    },
    {
        "country": "BW",
        "name": "Botswana",
        "currency": "BWP",
        "symbol": 'P'
    },
    {
        "country": "BR",
        "name": "Brazil",
        "currency": "BRL",
        "symbol": 'R$'
    },
    {
        "country": "IO",
        "name": "British Indian Ocean Territory",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "BG",
        "name": "Bulgaria",
        "currency": "BGN",
        "symbol": 'лв'
    },
    {
        "country": "BF",
        "name": "Burkina Faso",
        "currency": "XOF",
        "symbol": 'CFA'
    },
    {
        "country": "BI",
        "name": "Burundi",
        "currency": 'BIF',
        "symbol": 'FBu'
    },
    {
        "country": "KH",
        "name": "Cambodia",
        "currency": "KHR",
        "symbol": '៛'
    },
    {
        "country": "CM",
        "name": "Cameroon",
        "currency": "XAF",
        "symbol": 'FCFA'
    },
    {
        "country": "CA",
        "name": "Canada",
        "currency": "CAD",
        "symbol": '$'
    },
    {
        "country": "CV",
        "name": "Cape Verde",
        "currency": 'CVE',
        "symbol": '$'
    },
    {
        "country": "KY",
        "name": "Cayman Islands",
        "currency": "KYD",
        "symbol": '$'
    },
    {
        "country": "CF",
        "name": "Central African Republic",
        "currency": "XAF",
        "symbol": 'FCFA'
    },
    {
        "country": "TD",
        "name": "Chad",
        "currency": 'XAF',
        "symbol": 'FCFA'
    },
    {
        "country": "CL",
        "name": "Chile",
        "currency": "CLP",
        "symbol": '$'
    },
    {
        "country": "CN",
        "name": "China",
        "currency": "CNY",
        "symbol": '¥'
    },
    {
        "country": "CO",
        "name": "Colombia",
        "currency": "COP",
        "symbol": '$'
    },
    {
        "country": "KM",
        "name": "Comoros",
        "currency": "KMF",
        "symbol": 'CF'
    },
    {
        "country": "CG",
        "name": "Congo",
        "currency": "XAF",
        "symbol": 'FCFA'
    },
    {
        "country": "CD",
        "name": "Democratic Republic of the Congo",
        "currency": "CDF",
        "symbol": 'FC'
    },
    {
        "country": "CK",
        "name": "Cook Islands",
        "currency": "NZD",
        "symbol": '$'
    },
    {
        "country": "CR",
        "name": "Costa Rica",
        "currency": "CRC",
        "symbol": '₡'
    },
    {
        "country": "CI",
        "name": "Côte d'Ivoire",
        "currency": "XOF",
        "symbol": 'CFA'
    },
    {
        "country": "HR",
        "name": "Croatia",
        "currency": "HRK",
        "symbol": 'kn'
    },
    {
        "country": "CU",
        "name": "Cuba",
        "currency": "CUP",
        "symbol": '₱'
    },
    {
        "country": "CW",
        "name": "Curaçao",
        "currency": "ANG",
        "symbol": 'ƒ'
    },
    {
        "country": "CY",
        "name": "Cyprus",
        "currency": 'EUR',
        "symbol": '€'
    },
    {
        "country": "CZ",
        "name": "Czech Republic",
        "currency": "CZK",
        "symbol": 'Kč'
    },
    {
        "country": "DK",
        "name": "Denmark",
        "currency": "DKK",
        "symbol": 'kr'
    },
    {
        "country": "DJ",
        "name": "Djibouti",
        "currency": "DJF",
        "symbol": 'Fdj'
    },
    {
        "country": "DM",
        "name": "Dominica",
        "currency": "XCD",
        "symbol": '$'
    },
    {
        "country": "DO",
        "name": "Dominican Republic",
        "currency": "DOP",
        "symbol": 'RD$'
    },
    {
        "country": "EC",
        "name": "Ecuador",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "EG",
        "name": "Egypt",
        "currency": "EGP",
        "symbol": '£'
    },
    {
        "country": "SV",
        "name": "El Salvador",
        "currency": "SVC",
        "symbol": '$'
    },
    {
        "country": "GQ",
        "name": "Equatorial Guinea",
        "currency": "XAF",
        "symbol": 'FCFA'
    },
    {
        "country": "ER",
        "name": "Eritrea",
        "currency": "ERN",
        "symbol": 'Nfk'
    },
    {
        "country": "EE",
        "name": "Estonia",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "ET",
        "name": "Ethiopia",
        "currency": "ETB",
        "symbol": 'Br'
    },
    {
        "country": "FK",
        "name": "Falkland Islands (Malvinas)",
        "currency": 'FKP',
        "symbol": '£'
    },
    {
        "country": "FO",
        "name": "Faroe Islands",
        "currency": "DKK",
        "symbol": 'kr'
    },
    {
        "country": "FJ",
        "name": "Fiji",
        "currency": "FJD",
        "symbol": '$'
    },
    {
        "country": "FI",
        "name": "Finland",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "FR",
        "name": "France",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "PF",
        "name": "French Polynesia",
        "currency": 'XPF',
        "symbol": '₣'
    },
    {
        "country": "GA",
        "name": "Gabon",
        "currency": "XAF",
        "symbol": 'FCFA'
    },
    {
        "country": "GM",
        "name": "Gambia",
        "currency": "GMD",
        "symbol": 'D'
    },
    {
        "country": "GE",
        "name": "Georgia",
        "currency": "GEL",
        "symbol": '₾'
    },
    {
        "country": "DE",
        "name": "Germany",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "GH",
        "name": "Ghana",
        "currency": "GHS",
        "symbol": 'GH₵'
    },
    {
        "country": "GI",
        "name": "Gibraltar",
        "currency": "GIP",
        "symbol": '£'
    },
    {
        "country": "GR",
        "name": "Greece",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "GL",
        "name": "Greenland",
        "currency": "DKK",
        "symbol": 'kr'
    },
    {
        "country": "GD",
        "name": "Grenada",
        "currency": "XCD",
        "symbol": '$'
    },
    {
        "country": "GU",
        "name": "Guam",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "GT",
        "name": "Guatemala",
        "currency": "GTQ",
        "symbol": 'Q'
    },
    {
        "country": "GG",
        "name": "Guernsey",
        "currency": "GBP",
        "symbol": '£'
    },
    {
        "country": "GN",
        "name": "Guinea",
        "currency": "GNF",
        "symbol": 'FG'
    },
    {
        "country": "GW",
        "name": "Guinea-Bissau",
        "currency": "XOF",
        "symbol": 'CFA'
    },
    {
        "country": "HT",
        "name": "Haiti",
        "currency": "HTG",
        "symbol": 'G'
    },
    {
        "country": "HN",
        "name": "Honduras",
        "currency": "HNL",
        "symbol": 'L'
    },
    {
        "country": "HK",
        "name": "Hong Kong",
        "currency": "HKD",
        "symbol": '$'
    },
    {
        "country": "HU",
        "name": "Hungary",
        "currency": 'HUF',
        "symbol": 'Ft'
    },
    {
        "country": "IS",
        "name": "Iceland",
        "currency": 'ISK',
        "symbol": 'kr'
    },
    {
        "country": "IN",
        "name": "India",
        "currency": "INR",
        "symbol": '₹'
    },
    {
        "country": "ID",
        "name": "Indonesia",
        "currency": "IDR",
        "symbol": 'Rp'
    },
    {
        "country": "IR",
        "name": "Iran, Islamic Republic of",
        "currency": 'IRR',
        "symbol": '﷼'
    },
    {
        "country": "IQ",
        "name": "Iraq",
        "currency": "IQD",
        "symbol": 'ع.د'
    },
    {
        "country": "IE",
        "name": "Ireland",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "IM",
        "name": "Isle of Man",
        "currency": "GBP",
        "symbol": '£'
    },
    {
        "country": "IL",
        "name": "Israel",
        "currency": "ILS",
        "symbol": '₪'
    },
    {
        "country": "IT",
        "name": "Italy",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "JM",
        "name": "Jamaica",
        "currency": "JMD",
        "symbol": 'J$'
    },
    {
        "country": "JP",
        "name": "Japan",
        "currency": "JPY",
        "symbol": '¥'
    },
    {
        "country": "JE",
        "name": "Jersey",
        "currency": "GBP",
        "symbol": '£'
    },
    {
        "country": "JO",
        "name": "Jordan",
        "currency": "JOD",
        "symbol": 'JD'
    },
    {
        "country": "KZ",
        "name": "Kazakhstan",
        "currency": "KZT",
        "symbol": 'лв'
    },
    {
        "country": "KE",
        "name": "Kenya",
        "currency": "KES",
        "symbol": 'KSh'
    },
    {
        "country": "KI",
        "name": "Kiribati",
        "currency": "AUD",
        "symbol": '$'
    },
    {
        "country": "KP",
        "name": "North Korea",
        "currency": "KPW",
        "symbol": '₩'
    },
    {
        "country": "KR",
        "name": "South Korea",
        "currency": "KRW",
        "symbol": '₩'
    },
    {
        "country": "KW",
        "name": "Kuwait",
        "currency": "KWD",
        "symbol": 'KD'
    },
    {
        "country": "KG",
        "name": "Kyrgyzstan",
        "currency": "KGS",
        "symbol": 'лв'
    },
    {
        "country": "LA",
        "name": "Lao People's Democratic Republic",
        "currency": "LAK",
        "symbol": '₭'
    },
    {
        "country": "LV",
        "name": "Latvia",
        "currency": "LVL",
        "symbol": 'Ls'
    },
    {
        "country": "LB",
        "name": "Lebanon",
        "currency": "LBP",
        "symbol": '£'
    },
    {
        "country": "LS",
        "name": "Lesotho",
        "currency": "LSL",
        "symbol": 'M'
    },
    {
        "country": "LR",
        "name": "Liberia",
        "currency": "LRD",
        "symbol": '$'
    },
    {
        "country": "LY",
        "name": "Libya",
        "currency": "LYD",
        "symbol": 'LD'
    },
    {
        "country": "LI",
        "name": "Liechtenstein",
        "currency": 'CHF',
        "symbol": 'CHF'
    },
    {
        "country": "LT",
        "name": "Lithuania",
        "currency": "LTL",
        "symbol": 'Lt'
    },
    {
        "country": "LU",
        "name": "Luxembourg",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "MO",
        "name": "Macao",
        "currency": "MOP",
        "symbol": 'MOP$'
    },
    {
        "country": "MK",
        "name": "Republic of Macedonia",
        "currency": "MKD",
        "symbol": 'ден'
    },
    {
        "country": "MG",
        "name": "Madagascar",
        "currency": "MGA",
        "symbol": 'Ar'
    },
    {
        "country": "MW",
        "name": "Malawi",
        "currency": "MWK",
        "symbol": 'MK'
    },
    {
        "country": "MY",
        "name": "Malaysia",
        "currency": "MYR",
        "symbol": 'RM'
    },
    {
        "country": "MV",
        "name": "Maldives",
        "currency": "MVR",
        "symbol": 'Rf'
    },
    {
        "country": "ML",
        "name": "Mali",
        "currency": "XOF",
        "symbol": 'CFA'
    },
    {
        "country": "MT",
        "name": "Malta",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "MH",
        "name": "Marshall Islands",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "MQ",
        "name": "Martinique",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "MR",
        "name": "Mauritania",
        "currency": "MRO",
        "symbol": 'UM'
    },
    {
        "country": "MU",
        "name": "Mauritius",
        "currency": "MUR",
        "symbol": '₨'
    },
    {
        "country": "MX",
        "name": "Mexico",
        "currency": "MXN",
        "symbol": '$'
    },
    {
        "country": "FM",
        "name": "Micronesia, Federated States of",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "MD",
        "name": "Republic of Moldova",
        "currency": "MDL",
        "symbol": 'lei'
    },
    {
        "country": "MC",
        "name": "Monaco",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "MN",
        "name": "Mongolia",
        "currency": "MNT",
        "symbol": '₮'
    },
    {
        "country": "ME",
        "name": "Montenegro",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "MS",
        "name": "Montserrat",
        "currency": "XCD",
        "symbol": '$'
    },
    {
        "country": "MA",
        "name": "Morocco",
        "currency": "MAD",
        "symbol": 'MAD'
    },
    {
        "country": "MZ",
        "name": "Mozambique",
        "currency": "MZN",
        "symbol": 'MT'
    },
    {
        "country": "MM",
        "name": "Myanmar",
        "currency": "MMK",
        "symbol": 'K'
    },
    {
        "country": "NA",
        "name": "Namibia",
        "currency": "NAD",
        "symbol": '$'
    },
    {
        "country": "NR",
        "name": "Nauru",
        "currency": "AUD",
        "symbol": '$'
    },
    {
        "country": "NP",
        "name": "Nepal",
        "currency": "NPR",
        "symbol": '₨'
    },
    {
        "country": "NL",
        "name": "Netherlands",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "NZ",
        "name": "New Zealand",
        "currency": "NZD",
        "symbol": '$'
    },
    {
        "country": "NI",
        "name": "Nicaragua",
        "currency": "NIO",
        "symbol": 'C$'
    },
    {
        "country": "NE",
        "name": "Niger",
        "currency": "XOF",
        "symbol": 'CFA'
    },
    {
        "country": "NG",
        "name": "Nigeria",
        "currency": "NGN",
        "symbol": '₦'
    },
    {
        "country": "NU",
        "name": "Niue",
        "currency": "NZD",
        "symbol": '$'
    },
    {
        "country": "NF",
        "name": "Norfolk Island",
        "currency": "AUD",
        "symbol": '$'
    },
    {
        "country": "MP",
        "name": "Northern Mariana Islands",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "NO",
        "name": "Norway",
        "currency": "NOK",
        "symbol": 'kr'
    },
    {
        "country": "OM",
        "name": "Oman",
        "currency": "OMR",
        "symbol": '﷼'
    },
    {
        "country": "PK",
        "name": "Pakistan",
        "currency": "PKR",
        "symbol": '₨'
    },
    {
        "country": "PW",
        "name": "Palau",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "PS",
        "name": "Palestinian Territory",
        "currency": "ILS",
        "symbol": '₪'
    },
    {
        "country": "PA",
        "name": "Panama",
        "currency": "PAB",
        "symbol": 'B/.'
    },
    {
        "country": "PG",
        "name": "Papua New Guinea",
        "currency": "PGK",
        "symbol": 'K'
    },
    {
        "country": "PY",
        "name": "Paraguay",
        "currency": "PYG",
        "symbol": 'Gs'
    },
    {
        "country": "PE",
        "name": "Peru",
        "currency": "PEN",
        "symbol": 'S/.'
    },
    {
        "country": "PH",
        "name": "Philippines",
        "currency": "PHP",
        "symbol": '₱'
    },
    {
        "country": "PN",
        "name": "Pitcairn",
        "currency": "NZD",
        "symbol": '$'
    },
    {
        "country": "PL",
        "name": "Poland",
        "currency": "PLN",
        "symbol": 'zł'
    },
    {
        "country": "PT",
        "name": "Portugal",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "PR",
        "name": "Puerto Rico",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "QA",
        "name": "Qatar",
        "currency": "QAR",
        "symbol": '﷼'
    },
    {
        "country": "RO",
        "name": "Romania",
        "currency": "RON",
        "symbol": 'lei'
    },
    {
        "country": "RU",
        "name": "Russian",
        "currency": "RUB",
        "symbol": '₽'
    },
    {
        "country": "RW",
        "name": "Rwanda",
        "currency": "RWF",
        "symbol": 'R₣'
    },
    {
        "country": "KN",
        "name": "Saint Kitts and Nevis",
        "currency": "XCD",
        "symbol": '$'
    },
    {
        "country": "WS",
        "name": "Samoa",
        "currency": "WST",
        "symbol": 'WS$'
    },
    {
        "country": "SM",
        "name": "San Marino",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "ST",
        "name": "Sao Tome and Principe",
        "currency": "STD",
        "symbol": 'Db'
    },
    {
        "country": "SA",
        "name": "Saudi Arabia",
        "currency": "SAR",
        "symbol": '﷼'
    },
    {
        "country": "SN",
        "name": "Senegal",
        "currency": "XOF",
        "symbol": 'CFA'
    },
    {
        "country": "RS",
        "name": "Serbia",
        "currency": "RSD",
        "symbol": 'Дин.'
    },
    {
        "country": "SC",
        "name": "Seychelles",
        "currency": "SCR",
        "symbol": '₨'
    },
    {
        "country": "SL",
        "name": "Sierra Leone",
        "currency": "SLL",
        "symbol": 'Le'
    },
    {
        "country": "SG",
        "name": "Singapore",
        "currency": "SGD",
        "symbol": '$'
    },
    {
        "country": "SX",
        "name": "Sint Maarten",
        "currency": "ANG",
        "symbol": 'ƒ'
    },
    {
        "country": "SK",
        "name": "Slovakia",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "SI",
        "name": "Slovenia",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "SB",
        "name": "Solomon Islands",
        "currency": "SBD",
        "symbol": '$'
    },
    {
        "country": "SO",
        "name": "Somalia",
        "currency": "SOS",
        "symbol": 'S'
    },
    {
        "country": "ZA",
        "name": "South Africa",
        "currency": "ZAR",
        "symbol": 'R'
    },
    {
        "country": "SS",
        "name": "South Sudan",
        "currency": "",
        "symbol": ''
    },
    {
        "country": "ES",
        "name": "Spain",
        "currency": "EUR",
        "symbol": '€'
    },
    {
        "country": "LK",
        "name": "Sri Lanka",
        "currency": "LKR",
        "symbol": '₨'
    },
    {
        "country": "SD",
        "name": "Sudan",
        "currency": "SDG",
        "symbol": 'ج.س.'
    },
    {
        "country": "SR",
        "name": "Suriname",
        "currency": "SRD",
        "symbol": '$'
    },
    {
        "country": "SZ",
        "name": "Swaziland",
        "currency": "SZL",
        "symbol": 'E'
    },
    {
        "country": "SE",
        "name": "Sweden",
        "currency": "SEK",
        "symbol": 'kr'
    },
    {
        "country": "CH",
        "name": "Switzerland",
        "currency": "CHF",
        "symbol": 'CHF'
    },
    {
        "country": "SY",
        "name": "Syria",
        "currency": "SYP",
        "symbol": '£'
    },
    {
        "country": "TW",
        "name": "Taiwan, Province of China",
        "currency": "TWD",
        "symbol": 'NT$'
    },
    {
        "country": "TJ",
        "name": "Tajikistan",
        "currency": "TJS",
        "symbol": 'SM'
    },
    {
        "country": "TZ",
        "name": "Tanzania",
        "currency": "TZS",
        "symbol": 'TSh'
    },
    {
        "country": "TH",
        "name": "Thailand",
        "currency": "THB",
        "symbol": ''
    },
    {
        "country": "TG",
        "name": "Togo",
        "currency": "XOF",
        "symbol": 'CFA'
    },
    {
        "country": "TK",
        "name": "Tokelau",
        "currency": "NZD",
        "symbol": '$'
    },
    {
        "country": "TO",
        "name": "Tonga",
        "currency": "TOP",
        "symbol": 'T$'
    },
    {
        "country": "TT",
        "name": "Trinidad and Tobago",
        "currency": "TTD",
        "symbol": 'TT$'
    },
    {
        "country": "TN",
        "name": "Tunisia",
        "currency": "TND",
        "symbol": 'د.ت'
    },
    {
        "country": "TR",
        "name": "Turkey",
        "currency": "TRY",
        "symbol": '₺'
    },
    {
        "country": "TM",
        "name": "Turkmenistan",
        "currency": "TMT",
        "symbol": 'T'
    },
    {
        "country": "TC",
        "name": "Turks and Caicos Islands",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "TV",
        "name": "Tuvalu",
        "currency": "AUD",
        "symbol": '$'
    },
    {
        "country": "UG",
        "name": "Uganda",
        "currency": "UGX",
        "symbol": 'USh'
    },
    {
        "country": "UA",
        "name": "Ukraine",
        "currency": "UAH",
        "symbol": '₴'
    },
    {
        "country": "AE",
        "name": "United Arab Emirates",
        "currency": "AED",
        "symbol": 'د.إ'
    },
    {
        "country": "GB",
        "name": "United Kingdom",
        "currency": "GBP",
        "symbol": '£'
    },
    {
        "country": "UY",
        "name": "Uruguay",
        "currency": "UYU",
        "symbol": '$U'
    },
    {
        "country": "UZ",
        "name": "Uzbekistan",
        "currency": "UZS",
        "symbol": 'лв'
    },
    {
        "country": "VU",
        "name": "Vanuatu",
        "currency": "VUV",
        "symbol": 'VT'
    },
    {
        "country": "VE",
        "name": "Venezuela, Bolivarian Republic of",
        "currency": "VEF",
        "symbol": 'Bs'
    },
    {
        "country": "VN",
        "name": "Viet Nam",
        "currency": "VND",
        "symbol": '₫'
    },
    {
        "country": "VI",
        "name": "Virgin Islands",
        "currency": "USD",
        "symbol": '$'
    },
    {
        "country": "YE",
        "name": "Yemen",
        "currency": "YER",
        "symbol": '﷼'
    },
    {
        "country": "ZM",
        "name": "Zambia",
        "currency": "ZMK",
        "symbol": ''
    },
    {
        "country": "ZW",
        "name": "Zimbabwe",
        "currency": "ZWL",
        "symbol": ''
    }
];